import Errors from "../../../Errors";

export default {
    props: ["initialAddresses", "initialDefaultAddress", "countries"],

    data() {
        return {
            order_id: null,
            form: { state: "" },
            states: {},
            errors: new Errors(),
            formOpen: false,
            editing: false,
            loading: false,
        };
    },

    computed: {
    },

    created() {

    },

    methods: {
        cancel() {
            this.editing = false;
            this.formOpen = false;

            this.errors.reset();
            this.resetForm();
        },
        updateForm(order_id) {
            this.order_id = order_id;
            this.form = { state: "" };
            this.errors.clear();
            this.formOpen = true;
        },
        save() {
            this.loading = true;
            this.create();
        },
        create() {
            axios
                .post(route("account.refund.store"), {
                    ...this.form,
                    order_id: this.order_id,
                })
                .then(({ data }) => {
                    this.formOpen = false;
                    this.resetForm();
                    this.$notify(data.message);
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        this.errors.record(response.data.errors);
                    }

                    this.$notify(response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        resetForm() {
            this.form = { state: "" };
        },
    },
};
